import Image from 'next/image';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from '../../../hooks';
import { ICurrency, ILanguage, IMainNavBar } from '../../../models/strapi.models';
import { getCurrencySymbol } from '../../../shared/useUtils';
import { IAppPage } from '../../../store/slices/appSlice';
import { NavbarStyle } from '../../../styles/components/common/layout';
import { Button } from '../../common/ui';

export interface INavBarProps {
  items?: IMainNavBar;
  appPages: IAppPage[];
  appLanguages: ILanguage[];
  appCurrencies: ICurrency[];
  appLocale: string | undefined;
  appCurrentCurrency?: ICurrency | undefined;
  canShow: boolean;
  onUserLogout?: () => Promise<void>;
  onLocaleChange?: (languageCode: string, source: 'use-effect' | 'nav-bar', route?: { path: string; slug?: string }) => void;
  onCurrencyChange?: (currency: ICurrency) => void;
}

const NavBar = (props: INavBarProps) => {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const user = useAppSelector((state) => state.auth.user);
  const appState = useAppSelector((state) => state.app);
  const router = useRouter();
  const [redirectUrl, setRedirectUrl] = useState('');
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [mobileMenuItemsCollapsedState, setMobileMenuItemsCollapsedState] = useState({
    account: true,
    services: true,
    language: true,
    currency: true,
  });

  const getRoute = useCallback(
    (actualPath: string) => {
      return props.appPages?.find((x: IAppPage) => x && x.destination === actualPath && x.language === router.locale)?.source || actualPath;
    },
    [props.appPages, router.locale]
  );

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
    setTimeout(() => {
      document.querySelector(`.${NavbarStyle['navbar-mobile-scroller']}`)?.scrollTo({ top: 0 });
    }, 400);
  };

  const getSelectableLanguageLabel = (language: ILanguage | undefined) => {
    const label = language?.nameTranslations?.find((x) => x.language === props.appLocale)?.text || language?.nameTranslations?.find((x) => x.language === 'en')?.text;

    return label;
  };

  const handleDropDownToggle = (event: any) => {
    const element: HTMLDetailsElement = document.getElementById(event.target.id) as HTMLDetailsElement;
    const dropDownContent = element?.querySelector(`.${NavbarStyle['dropdown-content']}`);

    element?.open && dropDownContent ? dropDownContent?.classList.add(NavbarStyle['dropdown-content-animated']) : dropDownContent?.classList.remove(NavbarStyle['dropdown-content-animated']);
  };

  const handleDropDownLeaveToggle = (event: any) => {
    const detailsElement = event.composedPath().find((x: any) => x.localName === 'details');
    detailsElement.removeAttribute('open');
  };

  const handleDropDownLeaveToggleAll = (event: any) => {
    const elements: NodeListOf<HTMLDetailsElement> = document.querySelectorAll('details');

    elements.forEach((x) => {
      if (x.hasAttribute('open')) {
        x.removeAttribute('open');
      }
    });
  };

  const registerHandleToggleAll = useCallback(() => {
    document.addEventListener('click', (event: any) => {
      handleDropDownLeaveToggleAll(event);
    });
  }, []);

  const registerHandleDropDownLeaveToggle = useCallback(() => {
    const dropDownsItems = document.querySelectorAll(`.${NavbarStyle['dropdown-content']} li`);
    dropDownsItems.forEach((x) => x.addEventListener('click', handleDropDownLeaveToggle));
  }, []);

  const handleLanguageChange = (language: ILanguage) => {
    const languageDropdownElement = document.getElementById('language-dropdown');
    languageDropdownElement?.removeAttribute('open');
    if (!!props.onLocaleChange) {
      props.onLocaleChange(language.twoLetterIso as string, 'nav-bar', {
        path: router.pathname,
        slug: router.query.slug as string,
      });
    }
  };

  const handleCurrencyChange = (currency: ICurrency) => {
    if (!!props.onCurrencyChange) {
      props.onCurrencyChange(currency);
    }
    const currencyDropdownElement = document.getElementById('currency-dropdown');
    currencyDropdownElement?.removeAttribute('open');
  };

  const generateRedirectUrl = useCallback(() => {
    return router.locale !== 'en' ? `/${router.locale}${router.asPath}` : `${router.asPath}`;
  }, [router.asPath, router.locale]);

  const toggleAccountItems = () => {
    setMobileMenuItemsCollapsedState({ ...mobileMenuItemsCollapsedState, account: !mobileMenuItemsCollapsedState.account });
  };

  const toggleServicesItems = () => {
    setMobileMenuItemsCollapsedState({ ...mobileMenuItemsCollapsedState, services: !mobileMenuItemsCollapsedState.services });
  };

  const toggleLanguageItems = () => {
    setMobileMenuItemsCollapsedState({ ...mobileMenuItemsCollapsedState, language: !mobileMenuItemsCollapsedState.language });
  };

  const toggleCurrencyItems = () => {
    setMobileMenuItemsCollapsedState({ ...mobileMenuItemsCollapsedState, currency: !mobileMenuItemsCollapsedState.currency });
  };

  useEffect(() => {
    registerHandleToggleAll();
    registerHandleDropDownLeaveToggle();
    setRedirectUrl(`${router.locale !== 'en' ? `/${router.locale}` : ''}/account/login?redirectUrl=${generateRedirectUrl()}`);
  }, [registerHandleToggleAll, registerHandleDropDownLeaveToggle, router.pathname, router.query.slug, generateRedirectUrl, router.locale]);

  return (
    <div className={NavbarStyle.navbar}>
      {/* Mobile version */}
      {props.canShow && (
        <div className={`${NavbarStyle['navbar-mobile']} ${showMobileMenu ? NavbarStyle['navbar-mobile-show'] : ''}`}>
          <div className={NavbarStyle['navbar-mobile-header']}>
            <Image className={NavbarStyle['navbar-mobile-header-close']} src="/assets/images/icons/CloseIcon.svg" alt="Close mobile" width={32} height={32} onClick={() => toggleMobileMenu()} />
            <a className={NavbarStyle['shop-cart-icon-wrapper']} href={router.locale !== 'en' ? `/${router.locale}/cart` : `/cart`}>
              <Image src="/assets/images/icons/NavBarCartIcon.svg" alt="Cart" width={18} height={18} />
              <span className={NavbarStyle['shop-cart-icon-indicator']}>{appState.pendingOrdersCount}</span>
            </a>
            {!isAuthenticated && (
              <Button
                color={'minimal'}
                onClick={() => {
                  router.push(redirectUrl);
                  toggleMobileMenu();
                }}>
                {props.items?.loginLabel}
              </Button>
            )}
          </div>
          <div className={NavbarStyle['navbar-mobile-scroller']}>
            <ul className={NavbarStyle['navbar-mobile-items']}>
              <li>
                <Button
                  color={'primary'}
                  onClick={() => {
                    if (!router.route.includes('contact-us')) {
                      router.push(`${getRoute('/contact-us')}?source=${window.location.href}`);
                    }
                    toggleMobileMenu();
                  }}>
                  {props.items?.contactUsLabel}
                </Button>
              </li>
            </ul>
            {isAuthenticated && (
              <ul className={NavbarStyle['navbar-mobile-items']}>
                <li onClick={toggleAccountItems}>
                  <h3 className={NavbarStyle['large']}>
                    <span>{user?.user_info.name}</span>
                    <Image
                      className={`${NavbarStyle['dropdown-arrow']} ${mobileMenuItemsCollapsedState.account ? null : NavbarStyle['chevron-down']}`}
                      priority
                      src="/assets/images/icons/NavBarItemChevronIcon.svg"
                      alt="Services"
                      width={16}
                      height={10}
                    />
                  </h3>
                </li>
                {!mobileMenuItemsCollapsedState.account && (
                  <>
                    <li
                      className={NavbarStyle['navbar-mobile-sub-item']}
                      onClick={() => {
                        router.push('/account/profile');
                        toggleMobileMenu();
                      }}>
                      {props.items?.accountProfileLabel}
                    </li>
                    <li
                      className={NavbarStyle['navbar-mobile-sub-item']}
                      onClick={() => {
                        router.push('/account/orders');
                        toggleMobileMenu();
                      }}>
                      {props.items?.ordersLabel}
                    </li>
                    <li
                      className={NavbarStyle['navbar-mobile-sub-item']}
                      onClick={() => {
                        router.push('/account/portfolio');
                        toggleMobileMenu();
                      }}>
                      {props.items?.portfolioLabel}
                    </li>
                    <li
                      className={NavbarStyle['navbar-mobile-sub-item']}
                      onClick={() => {
                        router.push('/account/address-book');
                        toggleMobileMenu();
                      }}>
                      {props.items?.addressBookLabel}
                    </li>
                    <li
                      className={NavbarStyle['navbar-mobile-sub-item']}
                      onClick={() => {
                        props.onUserLogout;
                        toggleMobileMenu();
                      }}>
                      {props.items?.logoutLabel}
                    </li>
                  </>
                )}
              </ul>
            )}

            <hr className={NavbarStyle['navbar-mobile-divider']} />

            <ul className={NavbarStyle['navbar-mobile-items']}>
              <li onClick={toggleServicesItems}>
                <h3>
                  <span>{props.items?.servicesLabel}</span>
                  <Image
                    className={`${NavbarStyle['dropdown-arrow']} ${mobileMenuItemsCollapsedState.services ? null : NavbarStyle['chevron-down']}`}
                    priority
                    src="/assets/images/icons/NavBarItemChevronIcon.svg"
                    alt="Services"
                    width={16}
                    height={10}
                  />
                </h3>
              </li>
              {!mobileMenuItemsCollapsedState.services &&
                props.items?.servicesItems.map((value, index) => {
                  return (
                    <li className={NavbarStyle['navbar-mobile-sub-item']} key={index}>
                      <a href={getRoute(value.path)} onClick={() => toggleMobileMenu()}>
                        {value.label}
                      </a>
                    </li>
                  );
                })}
              <li>
                <a className={NavbarStyle['navbar-mobile-link']} href={getRoute(props.items?.pricingItem.path || '/')} onClick={() => toggleMobileMenu()}>
                  {props.items?.pricingItem.label}
                </a>
              </li>
              <li>
                <a className={NavbarStyle['navbar-mobile-link']} href={getRoute(props.items?.blogItem.path || '/')} onClick={() => toggleMobileMenu()}>
                  {props.items?.blogItem.label}
                </a>
              </li>
              <li>
                <a className={NavbarStyle['navbar-mobile-link']} href={getRoute(props.items?.aboutUsItem.path || '/')} onClick={() => toggleMobileMenu()}>
                  {props.items?.aboutUsItem.label}
                </a>
              </li>
            </ul>

            <hr className={NavbarStyle['navbar-mobile-divider']} />

            <ul className={NavbarStyle['navbar-mobile-items']}>
              <li onClick={toggleLanguageItems}>
                <h3>
                  <span>
                    <Image
                      className={NavbarStyle['navbar-mobile-items-language-flag']}
                      alt={`language-${props.appLanguages.find((x) => x.twoLetterIso === props.appLocale)?.twoLetterIso}`}
                      src={`/assets/images/common/languages/${props.appLanguages.find((x) => x.twoLetterIso === props.appLocale)?.twoLetterIso}.svg`}
                      height={24}
                      width={36}
                      sizes="24px"
                    />
                    {props.appLanguages && getSelectableLanguageLabel(props.appLanguages.find((x) => x.twoLetterIso === props.appLocale))}
                  </span>
                  <Image
                    className={`${NavbarStyle['dropdown-arrow']} ${mobileMenuItemsCollapsedState.language ? null : NavbarStyle['chevron-down']}`}
                    priority
                    src="/assets/images/icons/NavBarItemChevronIcon.svg"
                    alt="Services"
                    width={16}
                    height={10}
                  />
                </h3>
              </li>
              {!mobileMenuItemsCollapsedState.language &&
                props.appLanguages &&
                props.appLanguages.map((language, index) => {
                  if (language.twoLetterIso !== props.appLocale) {
                    return (
                      <li
                        className={NavbarStyle['navbar-mobile-sub-item']}
                        key={index}
                        onClick={() => {
                          handleLanguageChange(language);
                          toggleMobileMenu();
                        }}>
                        <Image
                          className={NavbarStyle['navbar-mobile-items-language-flag']}
                          alt={`language-${language.twoLetterIso}`}
                          src={`/assets/images/common/languages/${language.twoLetterIso}.svg`}
                          height={24}
                          width={36}
                          sizes="24px"
                        />
                        {getSelectableLanguageLabel(language)}
                      </li>
                    );
                  }
                })}
            </ul>

            <hr className={NavbarStyle['navbar-mobile-divider']} />

            <ul className={NavbarStyle['navbar-mobile-items']}>
              <li onClick={toggleCurrencyItems}>
                <h3>
                  <span>
                    {getCurrencySymbol(props.appCurrencies.find((value) => value.isoCode === props.appCurrentCurrency?.isoCode)?.isoCode)}{' '}
                    {props.appCurrencies && props.appCurrencies.find((value) => value.isoCode === props.appCurrentCurrency?.isoCode)?.isoCode}
                  </span>
                  <Image
                    className={`${NavbarStyle['dropdown-arrow']} ${mobileMenuItemsCollapsedState.currency ? null : NavbarStyle['chevron-down']}`}
                    priority
                    src="/assets/images/icons/NavBarItemChevronIcon.svg"
                    alt="Services"
                    width={16}
                    height={10}
                  />
                </h3>
              </li>
              {!mobileMenuItemsCollapsedState.currency &&
                props.appCurrencies &&
                props.appCurrencies.map((value, index) => {
                  if (value.isoCode !== props.appCurrentCurrency?.isoCode) {
                    return (
                      <li
                        className={NavbarStyle['navbar-mobile-sub-item']}
                        key={index}
                        onClick={() => {
                          handleCurrencyChange(value);
                          toggleMobileMenu();
                        }}>
                        {getCurrencySymbol(value.isoCode)} {value.isoCode}
                      </li>
                    );
                  }
                })}
            </ul>
          </div>
        </div>
      )}

      {/* Main navigation bar */}
      <nav className={NavbarStyle['navbar-content-block']}>
        {props.canShow && (
          <div className={`${NavbarStyle['navbar-content-slot']} ${NavbarStyle['navbar-content-slot-left']}`}>
            <a href={router.locale && router.locale !== 'en' ? `/${router.locale}` : '/'} aria-label="iGerent home">
              <Image src="/assets/igerent-logo.svg" priority alt="iGerent" sizes="136px" width={136} height={25} />
            </a>

            <ul className={NavbarStyle['navbar-items']}>
              <li>
                <details id="services-dropdown" className={NavbarStyle['dropdown']} onToggle={handleDropDownToggle}>
                  <summary className={`${NavbarStyle['dropdown-summary']} ${NavbarStyle['navbar-link']}`}>
                    <div className={NavbarStyle['dropdown-button']}>
                      <span>{props.items?.servicesLabel}</span>
                      <Image className={NavbarStyle['dropdown-arrow']} priority src="/assets/images/icons/NavBarItemChevronIcon.svg" alt="Services" width={12} height={8} />
                    </div>
                  </summary>
                  <div className={NavbarStyle['dropdown-content']}>
                    <ul>
                      {props.items?.servicesItems.map((value, index) => {
                        return (
                          <li key={index}>
                            <a href={getRoute(value.path)}>{value.label}</a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </details>
              </li>
              <li>
                <a className={NavbarStyle['navbar-link']} href={getRoute(props.items?.pricingItem.path || '/')}>
                  {props.items?.pricingItem.label}
                </a>
              </li>
              <li>
                <a className={NavbarStyle['navbar-link']} href={getRoute(props.items?.blogItem.path || '/')}>
                  {props.items?.blogItem.label}
                </a>
              </li>
              <li>
                <a className={NavbarStyle['navbar-link']} href={getRoute(props.items?.aboutUsItem.path || '/')}>
                  {props.items?.aboutUsItem.label}
                </a>
              </li>
            </ul>
            <Image className={NavbarStyle['navbar-mobile-burger']} src="/assets/images/icons/MenuIcon.svg" alt="Menu toggle mobile" width={50} height={50} onClick={() => toggleMobileMenu()} />
          </div>
        )}

        {props.canShow && (
          <div className={`${NavbarStyle['navbar-content-slot']} ${NavbarStyle['navbar-content-slot-right']}`}>
            <ul className={NavbarStyle['navbar-items']}>
              <li className={NavbarStyle['has-separator']}>
                <details id="language-dropdown" className={NavbarStyle['dropdown']} onToggle={handleDropDownToggle}>
                  <summary className={`${NavbarStyle['dropdown-summary']} ${NavbarStyle['navbar-link']}`}>
                    <div className={NavbarStyle['dropdown-button']}>
                      <span>{props.appLanguages && getSelectableLanguageLabel(props.appLanguages.find((x) => x.twoLetterIso === props.appLocale))}</span>
                      <Image className={NavbarStyle['dropdown-arrow']} priority src="/assets/images/icons/NavBarItemChevronIcon.svg" alt="Services" width={12} height={8} />
                    </div>
                  </summary>
                  <div className={NavbarStyle['dropdown-content']}>
                    <ul>
                      {props.appLanguages?.map((language, index) => {
                        if (language.twoLetterIso !== props.appLocale) {
                          return (
                            <li key={index} onClick={() => handleLanguageChange(language)}>
                              <span>{getSelectableLanguageLabel(language)}</span>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                </details>
              </li>
              <li className={NavbarStyle['has-separator']}>
                <details id="currency-dropdown" className={NavbarStyle['dropdown']} onToggle={handleDropDownToggle}>
                  <summary className={`${NavbarStyle['dropdown-summary']} ${NavbarStyle['navbar-link']}`}>
                    <div className={NavbarStyle['dropdown-button']}>
                      <span>{props.appCurrencies.find((value) => value.isoCode === props.appCurrentCurrency?.isoCode)?.isoCode}</span>
                      <Image className={NavbarStyle['dropdown-arrow']} priority src="/assets/images/icons/NavBarItemChevronIcon.svg" alt="Services" width={12} height={8} />
                    </div>
                  </summary>
                  <div className={NavbarStyle['dropdown-content']}>
                    <ul>
                      {props.appCurrencies.map((value, index) => {
                        if (value.isoCode !== props.appCurrentCurrency?.isoCode) {
                          return (
                            <li key={index} onClick={() => handleCurrencyChange(value)}>
                              <span>{value.isoCode}</span>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                </details>
              </li>
              <li>
                {isAuthenticated ? (
                  <details id="user-account-dropdown" className={NavbarStyle['dropdown']} onToggle={handleDropDownToggle}>
                    <summary className={`${NavbarStyle['dropdown-summary']} ${NavbarStyle['navbar-link']}`}>
                      <div className={NavbarStyle['dropdown-button']}>
                        <span>{user?.user_info.name}</span>
                        <Image className={NavbarStyle['dropdown-arrow']} src="/assets/images/icons/NavBarItemChevronIcon.svg" alt={user?.user_info.name || 'chevron'} width={12} height={8} />
                      </div>
                    </summary>
                    <div className={NavbarStyle['dropdown-content']}>
                      <ul>
                        <li onClick={() => router.push('/account/profile')}>
                          <span>
                            <strong>{props.items?.accountProfileLabel}</strong>
                          </span>
                        </li>
                        <li onClick={() => router.push('/account/orders')}>
                          <span>{props.items?.ordersLabel}</span>
                        </li>
                        <li onClick={() => router.push('/account/portfolio')}>
                          <span>{props.items?.portfolioLabel}</span>
                        </li>
                        <li onClick={() => router.push('/account/address-book')}>
                          <span>{props.items?.addressBookLabel}</span>
                        </li>
                        <li className={NavbarStyle['is-divider']}></li>
                        <li onClick={props.onUserLogout}>
                          <span>{props.items?.logoutLabel}</span>
                        </li>
                      </ul>
                    </div>
                  </details>
                ) : (
                  <a className={NavbarStyle['navbar-link']} href={redirectUrl}>
                    {props.items?.loginLabel}
                  </a>
                )}
              </li>
              <li>
                <a className={NavbarStyle['shop-cart-icon-wrapper']} href={router.locale !== 'en' ? `/${router.locale}/cart` : `/cart`}>
                  <Image src="/assets/images/icons/NavBarCartIcon.svg" priority alt="Cart" width={18} height={18} />
                  <span className={NavbarStyle['shop-cart-icon-indicator']}>{appState.pendingOrdersCount}</span>
                </a>
              </li>
              <li>
                <Button
                  color={'primary'}
                  onClick={() => {
                    if (!router.route.includes('contact-us')) {
                      router.push(`${getRoute('/contact-us')}?source=${window.location.href}`);
                    }
                  }}>
                  {props.items?.contactUsLabel}
                </Button>
              </li>
            </ul>
          </div>
        )}
      </nav>
    </div>
  );
};

export default NavBar;
